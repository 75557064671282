import Vue from "vue";
import Vuex from "vuex";
import { arrToObj } from "bh-mod";
import { $api, setProp, deleteProp } from "bh-mod";
import user from './user'

Vue.use(Vuex);

export default {
	state: {
		transactionType: {
			label: "All Transactions",
			value: "all",
		},
		allSettings: {},
		justUpdated: false,
		filter: {
			label: "Active Transactions",
			value: "active",
		},
		editor: null,
		settings: null,
		appSettings: {},
		templates: {},
		transactions: {},
		transactionsCount: 0,
		pageSize: 10,
		currentPage: 1,
		sortQuery: 'createdAt:DESC',
		lots: {},
		currentTransaction: {},
		currentReservation: {},
		contacts: {},
		signingParties: {},
		condoSettings: {},
		participant: {
			user: {},
			type: "",
			visible: false,
		},
		note: {
			note: {},
			type: "",
			visible: false,
		},
		pdfPreview: {
			url: null,
			file: null,
			show: false,
			callback: false,
			confirm: false,
			save: false,
			isManual: false,
			buttonText: "",
		},
		filterDrawer: {
			visible: false,
			filter: {
				lots: [],
				units: [],
				status: ['any'],
			},
		},
		worksheet: null,
		reservation: null,
		display: 'block',
		tags: [],
		productLock: 0,
		selectedProduct: '',
		reservationAmount: 0,
		reservationCurrency: 'USD',
		requiredMutualRelease: true,
		currentElevation: ''
	},
	mutations: {
		SET_CURRENT_ELEVATION(state, data) {
			state.currentElevation = data
		},
		SET_PRODUCT_LOCK(state, data) {
			state.productLock = data.time;
			state.selectedProduct = data.product;
		},
		TRANSACTION_TYPE(state, data) {
			state.transactionType = data;
		},
		SET_WORKSHEET(state, data) {
			state.worksheet = data;
		},
		SET_RESERVATION(state, data) {
			state.reservation = data;
		},
		SET_OPTIONS: (state, { type, where, action = "", what, key }) => {
			if (!type) type = "app";
			let settings = state.allSettings[type].options;
			what = JSON.parse(JSON.stringify(what));

			if (action.indexOf("array") === 0) {
				if (action === "arrayAdd") {
					if (!settings[where]) {
						settings[where] = [];
					}
					settings[where].push(what);
				} else if (action === "arrayEdit") {
					if (!key) key = "id";

					if (!settings.hasOwnProperty(where) || !what.hasOwnProperty(key))
						return;

					settings[where] = settings[where].map((x) =>
						x[key] === what[key] ? what : x
					);
				}
			} else if (!action) {
				settings[where] = what;
			}
		},
		SET_CURRENT_TRANSACTION: (state, tr) => {
			if (tr.id && !tr.envelopes) tr.envelopes = [];

			state.currentTransaction = tr;

			if (tr.id) state.transactions[tr.id] = tr;
		},
		SET_CURRENT_RESERVATION: (state, reservation) => {
			if (reservation.id && !reservation.envelopes) reservation.envelopes = [];

			state.currentReservation = reservation;
		},
		SHOW_MANUAL_UPLOAD(state, { file, callback, buttonText }) {
			let fileUrl = (window.URL || window.webkitURL).createObjectURL(file);
			openPDFInNewTab(fileUrl);

			state.pdfPreview = {
				url: null,
				file: file,
				show: true,
				callback,
				confirm: true,
				isManual: true,
				embed: true,
				buttonText: buttonText || "CONFIRM",
				title: "Please confirm that this is the signed copy",
			}
		},
		REVIEW_SAVE(state, { preview, callback }) {
			openPDFInNewTab(preview);

			state.pdfPreview = {
				url: preview,
				file: null,
				callback,
				show: true,
				confirm: true,
				save: true,
				isManual: false,
				title: "",
			}
		},
		REVIEW_SEND(state, { preview, callback, isManual, buttonText }) {
			openPDFInNewTab(preview);

			state.pdfPreview = {
				url: preview,
				file: null,
				callback,
				show: true,
				confirm: true,
				save: false,
				isManual,
				title: "",
				buttonText,
			}
		},
		PAGE_PREVIEW_CALLBACK: (state) => {
			if (typeof state.pdfPreview.callback === "function") {
				state.pdfPreview.callback();
			}
		},
		SHOW_NOTE_MODAL(state, data) {
			state.note = {
				note: data.note,
				type: data.type,
				visible: true,
			};
		},
		CLOSE_NOTE_MODAL(state) {
			state.note = {
				note: {},
				type: "",
				visible: false,
			};
		},
		SHOW_PARTICIPANT_MODAL(state, data) {
			state.participant = {
				user: data.user,
				type: data.type,
				visible: true,
			};
		},
		CLOSE_PARTICIPANT_MODAL(state) {
			state.participant = {
				user: {},
				type: "",
				visible: false,
			};
		},
		UPDATE_CONTRACTS: (state, array) => {
			array.forEach((x) => {
				setProp(state, ["templates", x.id], x);
			});
			state.justUpdated = true;
		},
		ADD_ENVELOPE: (state, data) => {
			if (state.currentTransaction && state.currentTransaction.envelopes) {
				state.currentTransaction.envelopes.push(data);
			} else if (state.currentReservation && state.currentReservation.envelopes) {
				state.currentReservation.envelopes.push(data);
			}
		},
		UPDATE_ENVELOPE: (state, data) => {
			if (state.currentTransaction && state.currentTransaction.envelopes) {
				let findIndex = state.currentTransaction.envelopes.findIndex(
					(x) => x.id === data.id
				);
				setProp(state, ["currentTransaction", "envelopes", findIndex], data);
			} else if (state.currentReservation && state.currentReservation.envelopes) {
				let findIndex = state.currentReservation.envelopes.findIndex(
					(x) => x.id === data.id
				);
				setProp(state, ["currentReservation", "envelopes", findIndex], data);
			}
			state.newStoreTime = Date.now();
		},
		SHOW_PREVIEW(state, data) {
			openPDFInNewTab(data)
			state.pdfPreview = { url: data, file: null, show: true }
		},
		CLOSE_PREVIEW: (state) => (state.pdfPreview = { url: null, file: null, show: false }),
		SET_EDITOR: (state, { editor, settings }) => {
			state.editor = editor;
			state.settings = settings;
		},
		LOAD_PAGE: (state, status = !state.loadingPage) =>
			(state.loadingPage = status),
		SET_PROP: (state, { where, what, del = false }) => {
			if (!where.length) return;
			if (del) return deleteProp(state, where);
			if (what.status === null) what.status = "bh_lead";
			else if (typeof what.status === "object") what.status = what.status.id;

			if (what.tags && what.tags.length) {
				what.tags = what.tags.map((x) => {
					if (!state.tags[x.id] && typeof x === "object")
						setProp(state, ["tags", x.id], x);
					return x.id;
				});
			}

			state.newStoreTime = Date.now();
			setProp(state, where, what);
		},
		SET_TRANSACTION: (state, { id, value }) => {
			state.transactions[id] = value;
		},
		CLOSE_DRAWERS: (state) => {
			state.formDrawer = {
				show: false,
				type: "",
				form: "",
			};
		},
		CLOSE_MODALS: (state) => {
			state.statusModal = { type: "", show: false };
			state.formModal = { show: false, type: "" };
		},
		SHOW_ADD_FORM: (state, data) => {
			state.formDrawer = {
				show: true,
				type: "add",
				form: "",
			};
		},
		SHOW_EDIT_FORM: (state, form) => {
			state.formDrawer = {
				show: true,
				type: "edit",
				form: form.id,
			};
		},
		SET_SETTINGS(state, data) {
			let temp = data;

			if (data.userApp == null) {
				temp.userApp = {
					options: {
						seenIntro: false,
					},
				};
			}
			if (data.app == null) {
				temp.app = {
					options: {
						brokerage: {},
						checklist: [],
						deposits: [],
						addOnDeposits: [],
						inclusions: [],
						participants: [],
						sellersAgents: [],
						solicitor: {},
						vendor: {},
					},
				};
			}
			if (!data.app.options.inclusions) {
				temp.app.options.inclusions = [];
			}
			if (!data.app.options.addOnDeposits) {
				temp.app.options.addOnDeposits = [];
			}
			if (!data.app.options.deposits) {
				temp.app.options.deposits = [];
			}
			if (!data.app.options.participants) {
				temp.app.options.participants = [];
			}
			if (!data.app.options.sellersAgents) {
				temp.app.options.sellersAgents = [];
			}
			if (!data.app.options.irrevocableDateDays) {
				temp.app.options.irrevocableDateDays = null;
			}
			state.allSettings = temp;
		},
		SET_APPDATA(state, data) {
			Object.entries(data).forEach(([key, value]) => {
				if (Array.isArray(value) && value[0] && value[0].id)
					value = arrToObj(value);
				state[key] = value;
			});
		},
		GET_STARTED: (state) => {
			state.getStarted = true;
		},
		SET_CONDO_SETTINGS(state, data) {
			state.condoSettings = data
		},
		CHANGE_DISPLAY(state, data) {
			state.display = data
		},
		SET_TRANSACTIONS(state, data) {
			let transactions = arrToObj(data)
			if (state.display === 'block') {
				state.transactions = { ...state.transactions, ...transactions }
			} else {
				state.transactions = transactions
			}
		},
		SET_TOTAL_RESULT(state, data) {
			state.transactionsCount = data
		},
		SET_TRANSACTIONS_PAGE: (state, pg = 1) => state.currentPage = pg,
		SET_PAGE_SIZE: (state, size = 10) => state.pageSize = size,
		EDIT_SORT_QUERY(state, data) {
			state.sortQuery = data
		},
		SET_TAGS(state, data = []) {
			state.tags = data
		},
		SET_RESERVATION_DETAILS(state, data) {
			state.reservationAmount = data.value || 0;
			state.reservationCurrency = data.currency || 'USD';
			state.requiredMutualRelease = data.requiredMutualRelease
		},
		OPEN_FILTER(state) {
			state.filterDrawer.visible = true;
		},
		CLOSE_FILTER(state) {
			state.filterDrawer.visible = false;
		},
		UPDATE_FILTER(state, data) {
			state.filterDrawer.filter = data;
		},
		RESET_DEFAULT_FILTER(state) {
			state.filterDrawer.filter = {
				lots: [],
				units: [],
				status: ['any']
			};
		},
	},
	actions: {
		SET_APPDATA: ({ commit }, data) => commit("SET_APPDATA", data),
		CLOSE_FORM_DRAWER: ({ commit }, { type, data }) => {
			if (type !== "click")
				commit("SET_PROP", {
					where: ["allForms", data.id],
					what: data,
					del: type === "delete",
				});
			commit("CLOSE_DRAWERS");
		},
		async FETCH_TRANSACTIONS({ state, commit }) {
			try {
				let currPage = state.currentPage;
				let start = (currPage - 1) * state.pageSize;
				let type = state.transactionType.value

				let query = `?_start=${start}&_limit=${state.pageSize}`;
				if (state.sortQuery.trim().length != 0) {
					query += '&_sort=' + state.sortQuery;
				}

				let statusQuery = type === 'created' ? `&status_in=${type}&status_in=pending` : `&status=${type}`

				query += type !== 'all' ? type !== 'archived' ? `${statusQuery}&archived=false` : `&archived=true` : '&archived=false';
				const { data } = await $api.get(`/transactions/:instance${query}`);
				commit('SET_TRANSACTIONS', data.items)
				commit('SET_TOTAL_RESULT', data.count)
			} catch (error) {
				console.error('Error while fetching transaction for', state.transactionType.value)
			}
		},
		async FETCH_TAGS({ commit }) {
			try {
				const { data } = await $api.get(`/tags/:instance?type=contacts`);
				commit('SET_TAGS', data)
			} catch (error) {
				console.error('Error while fetching contact tags')
			}
		},
		async FETCH_INVENTORY_SETTINGS({ commit }) {
			try {
				const { data } = await $api.get(`/settings/:instance/inventory`);
				const reservationAmount = data && data.options && data.options.reservationAmount || { value: 0, currency: 'USD' };
				const reservationRelease = data && data.options && data.options.reservationRelease || { requiredMutualRelease: true };
				commit('SET_RESERVATION_DETAILS', { ...reservationAmount, requiredMutualRelease: reservationRelease.requiredMutualRelease })
			} catch (error) {
				console.error('Error occurred while fetching unit list', error)
			}
		},

		async FETCH_RESERVATION_DETAILS({ state, commit }) {
			try {
				let { data } = await $api.get(`reservations/:instance/${state.currentReservation.id}`);
				commit('SET_CURRENT_RESERVATION', data)
			} catch (error) {
				console.error('Error occurred while reservation detils', error)
			}
		},
	},
	getters: {
		needsRelease(state, getters) {
			let envelopes = getters.signEnvelopes;
			let releaseExist = envelopes.some(
				(d) => d.type && d.type === "release" && d.status !== "voided"
			);
			let sofar =
				!releaseExist && !state.currentTransaction.status.includes("void");
			return sofar;
		},
		needsFullfilment(state, getters) {
			let envelopes = getters.signEnvelopes;
			let fulfillmentExist = envelopes.some(
				(d) => d.type && d.type === "fulfillment" && d.status !== "voided"
			);
			return (
				!fulfillmentExist &&
				state.currentTransaction.offerType === "conditional"
			);
		},
		isConditionalEnvelopeExist(state, getters) {
			let envelopes = getters.signEnvelopes;
			return envelopes.some(
				(d) => d.type && d.type !== "regular" && d.status !== "voided"
			);
		},
		signEnvelopes: (state) => {
			if (!state.currentTransaction || !state.currentTransaction.envelopes)
				return [];
			return state.currentTransaction.envelopes.filter(
				(x) => x.esign || x.isManual
			);
		},
		currentEnvelopes: (state) => {
			if (!state.currentTransaction.envelopes) return [];
			return state.currentTransaction.envelopes.map((envelope) => {
				envelope.signedSoFar = 0;
				envelope.signFull = 0;
				let signers = ''

				const recipientEvents = envelope.recipientEvents && envelope.recipientEvents.length && envelope.recipientEvents[envelope.recipientEvents.length - 1];
				if (!recipientEvents) {
					const recipients = envelope.envStatus && envelope.envStatus.data && envelope.envStatus.data.envelopeSummary && envelope.envStatus.data.envelopeSummary.recipients;
					signers = recipients && (recipients.signers || []).concat(recipients.carbonCopies || []).concat(recipients.intermediaries || []);

				} else {
					let recipients = recipientEvents && recipientEvents.data && recipientEvents.data.envelopeSummary && recipientEvents.data.envelopeSummary.recipients;
					signers = recipients && recipients.signers || [];
				}

				if (signers && signers.length) {
					const progress = [];

					signers.forEach(s => {
						let username = s.name;
						let status = s.status === 'created' ? 'pending' : s.status === 'delivered' ? 'opened' : s.status;
						let type = s.recipientType === 'signer' ? 'sign' : s.recipientType === 'carboncopy' ? 'cc' : s.recipientType === 'agent' ? 'agent' : s.recipientType.toLowerCase();
						let icon = s.status === 'sent' ? 'mail' : s.status === 'completed' ? 'check' : s.status === 'delivered' ? 'read' : s.status === 'created' ? 'loading' : '';
						let roleName = s.roleName;

						if (type === 'sign') {
							envelope.signFull++
							if (status === 'completed') {
								envelope.signedSoFar++
							}
						}

						progress.push({ username, status, type, icon, roleName })
					})

					envelope.progress = progress;
				}

				return envelope;
			});
		},
		currentSignersEmail: (state, getters) => {
			return getters.currentEnvelopes;
		},
		filteredTransactions: (state) => {
			return Object.values(state.transactions).filter((x) =>
				state.filter.value !== "all" ? x.type === state.filter.value : true
			);
		},
		appSettings: (state) => {
			return state.allSettings.app;
		},

		currentParticipants: (state) => {
			let { signingParty, envelopes = [], owners = [], status } = state.currentTransaction;
			let signers = {};
			let signingEmails = {};
			let canEdit = false;

			if (state.currentReservation && state.currentReservation.id) {
				signingParty = state.currentReservation.signingParty;
				envelopes = state.currentReservation.envelopes || [];
				owners = state.currentReservation.createdBy ? [state.currentReservation.createdBy] : [];
				status = state.currentReservation.status;
			}

			owners.forEach((owner) => {
				if (owner.id == (user.state && user.state.user && user.state.user.id)) {
					canEdit = true
				}
				signers[owner.email] = {
					name: `${owner.firstName} ${owner.lastName}`,
					email: owner.email,
					role: "Transaction Owner",
					company: owner.company,
					avatar: owner.avatar,
					locked: true,
					owner: true,
				};
			});

			const envelope = envelopes.find(e => e.main)
			let mainEnvelopSigned = false;
			if (['completed', 'void'].includes(status)) {
				mainEnvelopSigned = true;
			} else if (envelope && canEdit) {
				if (envelope.status === 'completed') {
					mainEnvelopSigned = true;
				} else {
					let mainEnvelopSigners = '';

					const recipientEvents = envelope.recipientEvents && envelope.recipientEvents.length && envelope.recipientEvents[envelope.recipientEvents.length - 1];
					if (!recipientEvents) {
						const recipients = envelope.envStatus && envelope.envStatus.data && envelope.envStatus.data.envelopeSummary && envelope.envStatus.data.envelopeSummary.recipients;
						mainEnvelopSigners = recipients && (recipients.signers || []).concat(recipients.carbonCopies || []).concat(recipients.intermediaries || []);

					} else {
						let recipients = recipientEvents && recipientEvents.data && recipientEvents.data.envelopeSummary && recipientEvents.data.envelopeSummary.recipients;
						mainEnvelopSigners = recipients && recipients.signers || [];
					}

					if (mainEnvelopSigners && mainEnvelopSigners.length) {
						mainEnvelopSigners.every(s => {
							if (s.recipientType === 'signer' && s.status === 'completed') {
								mainEnvelopSigned = true;
								return false;
							}
							return true
						})
					}
				}
			}

			envelopes.forEach((e) => {
				if (e.signers && e.signers.length) {
					e.signers.forEach((signOrder) => {
						if (signOrder.signers && signOrder.signers.length) {
							signOrder.signers.forEach((signer, i) => {
								signer.locked = !canEdit || mainEnvelopSigned;

								if (
									signer.signLocations &&
									Object.keys(signer.signLocations).length
								) {
									if (!signingEmails[signer.email])
										signingEmails[signer.email] = 1;
									else signingEmails[signer.email]++;
								}

								if (!signer.vendor)
									return;

								if (signers[signer.email]) {
									if (signer.role !== signers[signer.email].role)
										signers[signer.email].role = "Multiple Roles";
									if (!signers[signer.email].docs)
										signers[signer.email].docs = 0;
									signers[signer.email].docs++;
								} else {
									signers[signer.email] = signer;
								}
							});
						}
					});
				}
			});

			if (!signingParty) signingParty = {};
			Object.values(signingParty).forEach((sP) => {
				if (signers[sP.email] && signers[sP.email].locked) return;
				signers[sP.email] = sP;
			});
			return { signers, signingEmails };
		},
	},
};


function openPDFInNewTab(url) {
	const userAgent = navigator.userAgent;
	console.log('userAgent', userAgent)

	// Check if the user is using a mobile device then open pdf in new tab.
	if (userAgent.match(/(iPhone|iPad|Macintosh)/i) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
		window.open(url, '_blank');
	}
}
